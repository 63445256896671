.volunteer-section {
    background-image: url('/images/slide/Slide1.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: relative;
    padding-top: 40px;
    padding-bottom: 40px;
}

.volunteer-form {
    background-color: rgba(255, 255, 255, 0.7);
    border-radius: var(--border-radius-medium);
    position: relative;
    overflow: hidden;
}
