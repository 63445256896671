.site-footer {
    background-color: var(--site-footer-bg-color);
    padding-top: 70px;
}

.site-footer-bottom {
    background-color: var(--secondary-color);
    position: relative;
    z-index: 2;
    margin-top: 70px;
    padding-top: 25px;
    padding-bottom: 25px;
}

.site-footer-bottom a {
    color: var(--white-color);
}

.site-footer-link {
    color: var(--white-color);
}

.copyright-text {
    color: var(--section-bg-color);
    font-size: var(--copyright-font-size);
    margin-right: 30px;
}

.site-footer .custom-btn {
    font-size: var(--copyright-font-size);
}

.site-footer .custom-btn:hover {
    background: var(--primary-color);
}


.footer-menu {
    column-count: 2;
    margin: 0;
    padding: 0;
}

.footer-menu-item {
    list-style: none;
    display: block;
}

.footer-menu-link {
    font-size: var(--p-font-size);
    color: var(--white-color);
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
    margin-bottom: 5px;
}

@media screen and (max-width: 991px) {

    .site-footer {
        padding-top: 50px;
    }

    .copyright-text-wrap {
        justify-content: center;
    }

    .site-footer-bottom {
        text-align: center;
        margin-top: 50px;
    }

    .site-footer-bottom .footer-menu {
        margin-top: 10px;
        margin-bottom: 10px;
    }
}